import styles from "./Project.module.css";
import { ReactComponent as WorkInProgress } from "../../../assets/under_construction.svg";
import { Heading } from "@chakra-ui/react";

const Project = () => {
  return (
    <div className={styles["project-container"]}>
      <WorkInProgress className={styles["project-image"]} />
      <div className={styles["project-image-overlay"]}>
        <Heading as="h3" size="lg">
          Coming Soon
        </Heading>
        <Heading as="h4" size="md">
          This is in Devlopment
        </Heading>
      </div>
    </div>
  );
};

export default Project;
