import styles from "./Skills.module.css";
import { Heading } from "@chakra-ui/react";
import { ReactComponent as Hammer } from "../../assets/hammer.svg";
import Skill from "./Skill";

const Skills = ({ skills }) => {
  const tempSkills = [
    { progress: 100, text: "JavaScript" },
    { progress: 100, text: "HTML" },
    { progress: 90, text: "CSS" },
    { progress: 85, text: "ReactJS" },
    { progress: 85, text: "Angular" },
    { progress: 88, text: "C#" },
    { progress: 86, text: "TypeScript" },
    { progress: 85, text: "NodeJS" },
    { progress: 65, text: "GraphQL" },
    { progress: 100, text: "Git | Git-Flow" },
  ];

  return (
    <section id="skills" className={styles["skills-section"]}>
      <div className={styles["skills-heading"]}>
        <Hammer />
        <Heading as="h1" size="xl">
          Tech I Use
        </Heading>
        <p>
          These are a few of the technologies I work with along with my comfort
          level in them.
        </p>
      </div>
      <div className={styles["skills-container"]}>
        {tempSkills.map((skill, index) => {
          return <Skill skill={skill} key={index} />;
        })}
      </div>
    </section>
  );
};

export default Skills;
