import styles from "./Skill.module.css";
import { Heading } from "@chakra-ui/react";
import useOnScreen from "../../../Hooks/UseOnScreen";
import { useEffect, useRef } from "react";

const Skill = ({ skill }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    const node = ref.current;
    let id = setInterval(frame, 10);
    let width = 0;
    function frame() {
      if (width >= +skill.progress) {
        clearInterval(id);
      } else {
        width++;
        node.style.width = width + "%";
      }
    }
  }, [isVisible]);

  return (
    <div className={`${styles["skill-container"]}`}>
      <Heading as="h4" size="md">
        {skill.text}
      </Heading>
      <div ref={ref} className={styles["skill-progress"]}></div>
    </div>
  );
};

export default Skill;
