import styles from "./Hero.module.css";
import { Heading } from "@chakra-ui/react";
import { ReactComponent as HeroArt } from "../../assets/programming.svg";


const Hero = () => {
  return <div id="hero-banner" className={styles["hero-container"]}>
      <div className={`${styles["hero-text"]} ${styles["fade-in-animation"]}`}>
        <Heading  as="h1" size="4xl">Hi, I'm Collin.</Heading>
        <Heading className={styles["hero-text-name"]} as="h1" size="4xl">I'm a Web Developer.</Heading>
      </div>
      <div className={`${styles["hero-art"]} ${styles["fade-in-animation"]}`}>
        <HeroArt />
      </div>
  </div>;
};

export default Hero;
