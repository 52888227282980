import styles from "./Projects.module.css";
import Project from "./Project";
import { Heading } from "@chakra-ui/react";
import { ReactComponent as GitHub } from "../../assets/github.svg";

const Projects = ({ projects }) => {
  const tempProjects = [1];

  return (
    <section id="projects">
      <div className={styles["projects-heading"]}>
        <GitHub />
        <Heading as="h1" size="xl">
          Apps I've Built
        </Heading>
        <p>
          Here are some projects that I've made for fun or learning a new skill.
        </p>
      </div>
      <div className={styles["projects-container"]}>
        {tempProjects.map((project, index) => {
          return <Project key={index} />;
        })}
      </div>
    </section>
  );
};

export default Projects;
