import styles from "./Nav.module.css";
import { Heading, Divider, Button } from "@chakra-ui/react";
import { ReactComponent as TagSvg } from "../../assets/tag.svg";

const handleProjectsClick = () => {
  document.getElementById("projects").scrollIntoView({
    behavior: "smooth",
    block: 'center'
  });
};

const handleSkillsClick = () => {
  document.getElementById("skills").scrollIntoView({
    behavior: "smooth",
    block: 'center'
  });
};

const handleContactClick = () => {
  document.getElementById("contact").scrollIntoView({
    behavior: "smooth",
    block: 'center'
  });
};

const Nav = () => {
  return (
    <div className={styles["nav-bar"]}>
      <TagSvg />
      <Heading as="h1" size="md" className={styles["nav-name"]}>
        Collin Nigro
      </Heading>
      <Divider className={styles["nav-divider"]} orientation="vertical" />
      {/* <Button colorScheme="teal" variant="ghost" size="lg">
        About Me
      </Button> */}
      <Button
        colorScheme="teal"
        variant="ghost"
        size="lg"
        onClick={handleProjectsClick}
      >
        Projects
      </Button>
      <Button
        colorScheme="teal"
        variant="ghost"
        size="lg"
        onClick={handleSkillsClick}
      >
        Skills
      </Button>
      <Button
        colorScheme="teal"
        className={styles["nav-chat"]}
        onClick={handleContactClick}
      >
        Let's Chat
      </Button>
    </div>
  );
};

export default Nav;
