import styles from "./Contact.module.css";
import { useState } from "react";
import { ReactComponent as PhoneArt } from "../../assets/phone-call.svg";
import { Heading, Button, Input, Textarea } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Contact = () => {
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message }),
    })
      .then(() =>
        toast({
          title: "Message Sent.",
          description: "We've recieved your message.",
          position: "bottom-left",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
      )
      .catch((error) =>
        toast({
          title: "Message Failed.",
          description: "Looks like something went wrong.",
          position: "bottom-left",
          status: "warning",
          duration: 5000,
          isClosable: true,
        })
      );
  }

  return (
    <section id="contact" className={styles["contact-section"]}>
      <div className={styles["contact-art"]}>
        <PhoneArt />
      </div>
      <div className={styles["contact-form"]}>
        <form data-netlify="true" name="contact" onSubmit={handleSubmit}>
          <Heading as="h2" size="lg">
            Get in touch with me
          </Heading>
          <p>
            Whether you want to talk shop, or just grab a digital coffee, I'd
            love to chat with you!
          </p>
          <div className="relative mb-4">
            <label htmlFor="name">Name</label>
            <Input
              type="text"
              id="name"
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="message">Message</label>
            <Textarea
              id="message"
              name="message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <Button
            className={styles["contact-submit-btn"]}
            type="submit"
            colorScheme="teal"
          >
            Submit
          </Button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
